import { Box, Flex, Link, useColorModeValue } from "@chakra-ui/react";
import { Logo } from "../../components/Logo";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation();
  const corpEmail = t("Footer.corpEmail");
  return (
    <>
      <Box
        bg={useColorModeValue("gray.100", "gray.900")}
        px={4}
        position="fixed"
        bottom={0}
        left={0}
        right={0}
        zIndex={10}
      >
        <Flex
          h={16}
          alignItems={"center"}
          justifyContent={"space-between"}
          flexDirection={["column", "row"]}
        >
          <Flex>
            <Box mr={2}>{t("Footer.contactUs")}:</Box>
            <Link href={`mailto:${corpEmail}`} isExternal color="teal.500">
              {corpEmail}
            </Link>
          </Flex>
            <Link href="/docs/Политика_для_претендентов.pdf" download="Политика_для_претендентов.pdf" color="teal.500">
              {t(t("Footer.privacyPolicy"))}
            </Link>
            <Link href="/docs/Согласие_для_претендентов.pdf"  download="Согласие_для_претендентов.pdf" color="teal.500">
              {t(t("Footer.consentToProcessPersonalData"))}
            </Link>
          <Logo sx={{ w: "36px", h: "36px" }} />
        </Flex>
      </Box>
    </>
  );
};
